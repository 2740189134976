










import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import KpiRadialChartData from './modelsKpiRadial';

@Component
export default class FormBar extends FormBase {
  data: KpiRadialChartData = {
    title: '',
    description: '',
    icon: '',
    valueField: '',
    targetField: '',
  };
}
